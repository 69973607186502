import React from "react";
import logo from "./assets/logo.png";
import { GooglePlayButton, AppStoreButton } from "react-mobile-app-button";

import "./App.css";

function App() {
  const iOSUrl = "https://apps.apple.com/us/app/munditur/id6448245837";
  const androidUrl =
    "https://play.google.com/store/apps/details?id=br.com.munditur.app";
  return (
    <div className="App">
      <header className="header">
        <img src={logo} className="logo" alt="logo" />
      </header>
      <div className="body">
        <p>Faça o download do nosso aplicativo</p>
        <div className="buttonsHolder">
          <GooglePlayButton url={androidUrl} theme={"dark"} height={50} />
          <AppStoreButton url={iOSUrl} theme={"dark"} height={50} />
        </div>
      </div>
    </div>
  );
}

export default App;
